import React, { useState } from 'react';
import { Box, Flex, Stack, Select, Image, Textarea } from '@chakra-ui/core';
import axios from 'axios';
import SwipeableViews from 'react-swipeable-views';

import theme, { colors } from '@theme';
import { TextInput, Button } from '@zeta';
import { H3, Text } from '@typography';
import { MobileOnly } from '../../layouts/Footer';
import { postcards } from '../../lib/consts';

const MobileCarousel = () => {
  const [activeImageId, setActiveImageId] = useState(1);
  const activePostCard = postcards.filter(postcard => postcard.id === activeImageId);

  const handleIndexChange = idx => {
    setActiveImageId(idx + 1);
  };

  return (
    <Box pt="38px">
      <SwipeableViews onChangeIndex={handleIndexChange}>
        {postcards.map(postcard => (
          <Image
            shadow="2xl"
            key={postcard.id}
            width="245px"
            height="189px"
            src={postcard.image}
            alt={postcard.alt}
          />
        ))}
      </SwipeableViews>

      <Flex w="100px" justify="space-evenly" align="center" direction="row" pt="26px">
        {postcards.map(postcard => {
          const active = postcard.id === activePostCard[0].id;
          return (
            <Box
              key={postcard.id}
              borderRadius="30px"
              backgroundColor={colors.primary}
              w={active ? '7px' : '5px'}
              h={active ? '7px' : '5px'}
              opacity={active ? null : 0.5}
            />
          );
        })}
      </Flex>
    </Box>
  );
};

const ZetaSelect = ({ onChange, defaultOptionSelect }) => {
  return (
    <Select
      onChange={onChange}
      style={{ ...styles.formStyle, color: defaultOptionSelect && 'gray' }}
    >
      <option
        style={{
          color: 'gray',
        }}
        selected
        hidden
        disabled
        value=""
      >
        Pick a postcard
      </option>
      {postcards.map(postcard => (
        <option key={postcard.id} value={postcard.displayText}>
          {postcard.displayText}
        </option>
      ))}
    </Select>
  );
};

const LoveNoteForm = () => {
  const [postCard, setPostCard] = useState('');
  const [name, setName] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [message, setMessage] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [defaultOptionSelect, setDefaultOptionSelected] = useState(true);

  const handlePostcardSelect = e => {
    e.preventDefault();

    if (e.target.value === '') {
      setDefaultOptionSelected(true);
    } else {
      setDefaultOptionSelected(false);
    }
    setPostCard(e.target.value);
  };

  const handleName = e => {
    e.preventDefault();
    setName(e.target.value);
  };

  const handleSenderEmail = e => {
    e.preventDefault();
    setSenderEmail(e.target.value);
  };

  const handleMessage = e => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const handleRecipientEmail = e => {
    e.preventDefault();
    setRecipientEmail(e.target.value);
  };

  const sendIt = async () => {
    const url = `${process.env.GATSBY_HIVE_URL}/subscribers/send_with_love`;

    const chosenPostcard = postcards.find(postcard => {
      if (postcard.displayText === postCard) {
        return postcard;
      }
    });

    const response = await axios.post(url, {
      card_type: chosenPostcard.cardType,
      note: message,
      email: recipientEmail,
      sender_name: name,
      source: 'website',
      sender_email: senderEmail,
    });

    if (response.status === 200) {
      window.alert('Your love note is on the way!');
    } else {
      window.alert(
        'Your love note could not be sent, please double check your internet connection and try again!',
      );
    }
  };

  const isDisabled = () => {
    if (postCard.trim() === '' || postCard === null) {
      return true;
    }
    if (name.trim() === '' || name === null) {
      return true;
    }
    if (senderEmail.trim() === '' || senderEmail === null) {
      return true;
    }
    if (message.trim() === '' || message === null) {
      return true;
    }
    if (recipientEmail.trim() === '' || recipientEmail === null) {
      return true;
    }

    return false;
  };

  const clearState = () => {
    setPostCard('');
    setName('');
    setSenderEmail('');
    setMessage('');
    setRecipientEmail('');
  };

  return (
    <Box pt="68px">
      <H3>Send your love note</H3>

      <Box width={['300px', 'auto']} textAlign={['center', 'left']} pt="13px">
        <Text>You write the note, we'll sign, seal and deliver it.</Text>
      </Box>

      <MobileOnly>
        <MobileCarousel />
      </MobileOnly>

      <Stack pt="48px" spacing="18px" width="325px">
        <ZetaSelect
          defaultOptionSelect={defaultOptionSelect}
          onChange={handlePostcardSelect}
        />
        <TextInput
          onChange={handleName}
          placeholder="Your name (as you want it to appear)"
        />
        <TextInput onChange={handleSenderEmail} placeholder="Your email" />
        <Textarea
          onChange={handleMessage}
          style={styles.formStyle}
          placeholder="Your message"
          minH="126px"
        />
        <TextInput onChange={handleRecipientEmail} placeholder="Recipient's email" />
        <Button
          onClick={sendIt}
          disabled={isDisabled()}
          text="Send it"
          variant="greenPea"
        />
      </Stack>
    </Box>
  );
};

const styles = {
  formStyle: {
    height: '3.125rem',
    border: `1px solid ${colors.black}`,
    borderRadius: theme.space['2'],
  },
};

export default LoveNoteForm;
