import React, { useState, useMemo } from 'react';
import { Box, Flex, Image, SimpleGrid } from '@chakra-ui/core';
import Layout from '../components/layout';
import Helmet from 'react-helmet';
import SwipeableViews from 'react-swipeable-views';

import ContentContainer from '../components/ContentContainer';
import { APP_CONTENT_WIDE_WIDTH } from '../styles/sizes';
import { colors } from '@theme';
import { H1, H3, Text } from '@typography';
import { Button } from '@zeta';
import { LoveNoteForm } from '@sentwithlove';

import { cloudfrontBaseUrl, postcards, favoriteNotes } from '../lib/consts';
import { DesktopOnly, MobileOnly } from '../layouts/Footer';

const PostcardGrid = () => {
  return (
    <Box>
      <SimpleGrid position="relative" pt="146px" columns={3} spacing="18px">
        {postcards.map(postcard => (
          <Image
            key={postcard.id}
            zIndex={3}
            w="170px"
            h="130px"
            src={postcard.image}
            alt={postcard.alt}
          />
        ))}
      </SimpleGrid>
      <Image
        position="relative"
        w="210px"
        h="166px"
        src={`${cloudfrontBaseUrl}/sent-with-love/postcards/envelope-background.png`}
        mt="-100px"
        ml="240px"
        alt="envelope with zeta stamp"
      />
    </Box>
  );
};

const socialIcons = [
  {
    name: 'mail',
    imageUrl: `${cloudfrontBaseUrl}/sent-with-love/social-icons/mail.png`,
    link: '',
    height: '28px',
    alt: 'envelope',
  },
  {
    name: 'facebook',
    imageUrl: `${cloudfrontBaseUrl}/sent-with-love/social-icons/facebook.png`,
    link:
      'https://www.facebook.com/sharer/sharer.php?u=https%3A//www.askzeta.com/sentwithlove?utm_medium=content%26utm_source=brand-camp%26utm_campaign=sentwithlove%26utm_content=share',
    height: '28px',
    alt: 'facebook logo',
  },
  {
    name: 'twitter',
    imageUrl: `${cloudfrontBaseUrl}/sent-with-love/social-icons/twitter.png`,
    link:
      'https://twitter.com/intent/tweet?text=Send%20a%20love%20note%20to%20that%20special%20someone%20(cough%20yours%20truly%20cough)%20and%20maybe%20I%E2%80%99ll%20send%20you%20one%20back%20%23sentwithlove%20http%3A//bit.ly/2UyUMfr',
    height: '28px',
    alt: 'twitter logo',
  },
];

const Metadata = () => {
  const helmet = useMemo(
    () => (
      <Helmet>
        <title>Zeta&#039;s #SentWithLove Campaign</title>
        <meta
          name="description"
          content="Write a free love note on us! We'll sign, seal and deliver it for you."
        />
        <meta
          name="keywords"
          content="millennials,couples,love story,love notes,romance,goals,relationships,newlyweds,love,sent with love"
        />
        <link rel="canonical" href="https://www.askzeta.com/sentwithlove" />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Spread the love! Send a hand-written note to your favorite person at: https://askzeta.com/sentwithlove"
        />
        <meta property="og:description" content="Zeta's #sentwithlove campaign" />
        <meta
          property="og:site_name"
          content="Spread the love! Send a hand-written note to your favorite person and have it delivered by @AskZetaApp via https://askzeta.com/sentwithlove"
        />
        <meta property="og:url" content="http://www.askzeta.com/sentwithlove" />

        <meta
          property="og:image"
          content="http://v.fastcdn.co/u/77dd88b6/51058448-0-SentWithLove-Board-C.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://v.fastcdn.co/u/77dd88b6/51058448-0-SentWithLove-Board-C.png"
        />
        <meta property="fb:app_id" content="2316257168617802" />
        <meta name="theme-color" content={colors.blueLight} />
      </Helmet>
    ),
    [],
  );
  return <Flex>{helmet}</Flex>;
};

const SentWithLove = () => {
  const [activeNoteId, setActiveNoteId] = useState(1);
  const activeNote = favoriteNotes.filter(note => note.id === activeNoteId);

  const handleIndexChange = idx => {
    setActiveNoteId(idx + 1);
  };

  return (
    <Layout noRootContainer customBackgroundColor={colors.blueLight}>
      <Metadata />

      <Box className="sent-with-love-main-container" background={colors.blueLight}>
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <Flex
            direction="column"
            justify="center"
            align="center"
            pt={['120px', '9.875rem']}
          >
            <Flex
              align="center"
              justify="center"
              height="2rem"
              width="9.188rem"
              mb={['16px', '18px']}
              sx={{
                border: `1px solid #000000`,
                borderRadius: '40px',
              }}
            >
              <Text size="16px">ZETA PRESENTS</Text>
            </Flex>
            <H1>#sentwithlove</H1>
            <Flex
              textAlign="center"
              pt={['26px', '24px']}
              direction="column"
              align="center"
            >
              <DesktopOnly>
                <Text>
                  When's the last time you told someone you loved them? Just so you know,
                  the dog doesn't
                  <br />
                  count. We think it's always a good time to celebrate love - for your
                  significant other, your mom, or your best
                  <br />
                  friends. At Zeta, we're on a mission to help love thrive, and because of
                  that, we make it easy (and free) to spread the love.
                  <br />
                  <br />
                  Write your loved one a postcard below, and we'll send it to them with a
                  bow.
                </Text>
              </DesktopOnly>

              <MobileOnly>
                <Box width="280px">
                  <Text size="18px">
                    When's the last time you told someone you loved them? Just so you
                    know, the dog doesn't count. We think it's always a good time to
                    celebrate love - for your significant other, your mom, or your best
                    friends. At Zeta, we're on a mission to help love thrive, and because
                    of that, we make it easy (and free) to spread the love.
                    <br />
                    <br />
                    Write your loved one a postcard below, and we'll send it to them with
                    a bow.
                  </Text>
                </Box>
              </MobileOnly>

              <Button
                text="Send a free note"
                variant="greenPea"
                style={{
                  marginTop: '45px',
                }}
                onClick={() => {
                  window.location.href = '#send-your-love-note';
                }}
              />

              <Flex
                id="send-your-love-note"
                direction="row"
                width="134px"
                justify="space-between"
                my="45px"
              >
                {socialIcons.map(socialIcon => (
                  <a
                    href={
                      socialIcon.name !== 'mail'
                        ? socialIcon.link
                        : 'mailto:?subject=#sentwithlove&body=Send a love note to that special someone (cough yours truly cough) and maybe I’ll send you one back: https://askzeta.com/sentwithlove'
                    }
                    target="_blank"
                    key={socialIcon.name}
                  >
                    <Image
                      src={socialIcon.imageUrl}
                      height={socialIcon.height}
                      alt={socialIcon.alt}
                    />
                  </a>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </ContentContainer>

        <DesktopOnly>
          <ContentContainer maxWidth="1500px">
            <Flex
              direction="column"
              backgroundColor={colors.blue}
              mx="78px"
              px="68px"
              borderRadius="15px"
            >
              <Flex justify="space-between" className="top-section" direction="row">
                <LoveNoteForm />
                <PostcardGrid />
              </Flex>

              <Flex pt="68px" className="inspiration-section" direction="column">
                <H3>Need a little inspiration?</H3>
                <Box pt="13px">
                  <Text>Here are some of our favorite notes:</Text>
                </Box>

                <SimpleGrid pt="48px" pb="102px" columns={4} spacing="36px">
                  {favoriteNotes.map(note => (
                    <Image key={note.id} src={note.image} alt={note.alt} />
                  ))}
                </SimpleGrid>
              </Flex>
            </Flex>
          </ContentContainer>

          <Flex className="who-is-zeta" direction="column" py="60px">
            <ContentContainer maxWidth="1500px">
              <Flex
                direction="column"
                borderRadius="15px"
                backgroundColor={colors.iceberg}
                mx="78px"
                px="68px"
                align="center"
              >
                <Box pt="60px">
                  <H3>Who is Zeta? Why are we doing this?</H3>
                </Box>
                <Box pt="38px" pb="55px" textAlign="center">
                  <Text>
                    Zeta's a financial institution focused on helping families thrive. We
                    offer modern-day joint accounts to make sharing money easy.
                    <br />
                    The nutty thing is - money remains a massive source of anxiety for
                    many families, but we think technology can play a role in easing
                    <br />
                    that. So we built an account that takes over the chore of managing
                    money together - whether it's coordinating bills, tracking your
                    <br />
                    money, or splitting stuff between each other. Best of all, it's free.
                    So what we're saying is: our love don't cost a thing.
                  </Text>
                </Box>
              </Flex>
            </ContentContainer>
          </Flex>
        </DesktopOnly>

        <MobileOnly>
          <>
            <Flex
              id="send-your-love-note"
              backgroundColor={colors.blue}
              direction="column"
              pb="60px"
            >
              <Box align="center">
                <LoveNoteForm />
              </Box>
            </Flex>

            <Flex
              backgroundColor={colors.greenLight}
              direction="column"
              align="center"
              pt="56px"
            >
              <H3>Need a little inspiration?</H3>
              <Box pt="13px">
                <Text>Here are some of our favorite notes:</Text>
              </Box>

              <Box align="center">
                <SwipeableViews onChangeIndex={handleIndexChange}>
                  {favoriteNotes.map(note => {
                    return (
                      <Image
                        mt="28px"
                        h="146px"
                        w="254px"
                        src={note.image}
                        alt={note.alt}
                      />
                    );
                  })}
                </SwipeableViews>
              </Box>
              <Flex
                pt="30px"
                pb="50px"
                direction="row"
                w="100px"
                align="center"
                justify="space-evenly"
              >
                {favoriteNotes.map(note => {
                  const active = note.id === activeNote[0].id;
                  return (
                    <Box
                      key={note.id}
                      borderRadius="30px"
                      backgroundColor={colors.primary}
                      w={active ? '7px' : '5px'}
                      h={active ? '7px' : '5px'}
                      opacity={active ? null : 0.5}
                    />
                  );
                })}
              </Flex>
            </Flex>

            <Flex
              backgroundColor={colors.iceberg}
              direction="column"
              align="center"
              pb="60px"
            >
              <Box pt="60px">
                <H3>
                  Who is Zeta? Why are we
                  <br />
                  doing this?
                </H3>
              </Box>

              <Box width="324px" pt="12px">
                <Text
                  size="18px"
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Zeta's a financial institution focused on helping families thrive. We
                  offer modern-day joint accounts to make sharing money easy. The nutty
                  thing is - money remains a massive source of anxiety for many families,
                  but we think technology can play a role in easing that. So we built an
                  account that takes over the chore of managing money together - whether
                  it's coordinating bills, tracking your money, or splitting stuff between
                  each other. Best of all, it's free. So what we're saying is: our love
                  don't cost a thing.
                </Text>
              </Box>
            </Flex>
          </>
        </MobileOnly>
      </Box>
    </Layout>
  );
};

export default SentWithLove;
